// 用户列表
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/crm",
    name: "crm",
    component: () => import("@/views/Home.vue"),
    meta: { title: "用户管理" },
    children: [
      {
        path: "/crm/crmList", // 用户列表路由
        name: "crmList",
        meta: { title: "用户列表", isShow: true },
        component: () => import("@/views/crm/CrmList.vue"),
        children: [
          {
            path: "/crm/crmDetail/:id", // 用户列表详情路由
            name: "crmDetail",
            meta: { title: "详情", isShow: true },
            component: () => import("@/views/crm/CrmDetail.vue"),
          },
        ],
      },
      {
        path: "/crm/tortList",
        name: "tortList",
        meta: { title: "侵权报案", isShow: true },
        component: () => import("@/views/crm/tort/TortList.vue"),
        children: [
          {
            path: "/crm/tortDetail/:id", // 详情
            name: "tortDetail",
            meta: { title: "详情", },
            component: () => import("@/views/crm/tort/TortDetail.vue"),
          },
        ],
      }
    ],
  },
];
