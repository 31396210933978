import Vue from "vue";
import VueRouter from "vue-router";
import RolePower from "./rolePower/router";
import SystemManage from "./systemManage/router";
import Certificate from "./certificate/router";
import Crm from "./crm/router";
import Operate from "./operate/router";
import { message } from "ant-design-vue";

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch((err) => {});
};

Vue.use(VueRouter);
// 所有路由集合
let allRouterArr = [
  ...RolePower,
  ...SystemManage,
  ...Certificate,
  ...Crm,
  ...Operate,
];
//  动态路由
let powerListArr = JSON.parse(localStorage.getItem("powerListArr"));
if (powerListArr) {
  let powerListObj = powerListArr.reduce((pre, current) => {
    pre[current.code] = "xxx";
    return pre;
  }, {});
  let childPowerListArr = [];
  powerListArr.map((item) => {
    if (item.children.length > 0) {
      item.children.map((subitem) => {
        childPowerListArr.push(subitem);
      });
    }
  });
  let childPowerListObj = childPowerListArr.reduce((pre, current) => {
    pre[current.code] = "xxx";
    return pre;
  }, {});

  let newAllRouterArr = []; // 过滤后的路由数组
  allRouterArr.map((item) => {
    let subNewAllRouterArr = [];
    if (powerListObj[item.name]) {
      newAllRouterArr.push(item);
      item.children.forEach((subItem) => {
        if (childPowerListObj[subItem.name]) {
          subNewAllRouterArr.push(subItem);
        }
      });
      if (item.children.length > 0) {
        item.children = subNewAllRouterArr;
      }
    }
  });
  allRouterArr = newAllRouterArr;
}

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login", // 登录页
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home", // 首页
    name: "home",
    redirect: "/home/home",
    meta: { title: "首页" },
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/home/home", // 控制台
        name: "subHome",
        meta: { title: "控制台" },
        component: () => import("@/views/public/Home.vue"),
      },
    ],
  },
  ...allRouterArr, // Nothingness
];
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  if (!token && to.path != "/login") {
    message.error("token失效,请重新登录");
    router.push(`/login`);
  }
  next();
});
export default router;
