// 证书的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/certificate",
    name: "certificate",
    component: () => import("@/views/Home.vue"),
    meta: { title: "证书管理" },
    children: [
      {
        path: "/certificate/cunZheng", // 角色管理路由
        name: "cunZheng",
        meta: { title: "版权存证", isShow: true },
        component: () =>
          import("@/views/certificate/cunZheng/CunZhengList.vue"),
        children: [
          {
            path: "/certificate/cunZheng/cunZhengDetaile/:id", // 证书路由
            name: "cunZhengDetaile",
            meta: { title: "存证详情", isShow: true },
            component: () =>
              import("@/views/certificate/cunZheng/CunZhengDetaile.vue"),
          },
        ],
      },
      {
        path: "/certificate/dengji", // 登记管理路由
        name: "dengji",
        meta: { title: "版权登记", isShow: true },
        component: () => import("@/views/certificate/dengji/List.vue"),
        children:[
          {
            path: "/certificate/dengji/dengjiDetaile/:id", // 角色管理路由
            name: "dengjiDetaile",
            meta: { title: "存证详情", isShow: true },
            component: () => import("@/views/certificate/dengji/DengjiDetaile.vue"),
          }
        ]
      },
    ],
  },
];
