// 系统管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/systemManage',   // 系统管理
        name: 'systemManage',
        component: () => import('@/views/Home.vue'),
        redirect: "/systemManage/menuManage",
        meta: { title: '系统管理' },
        children: [
            {
                path: '/systemManage/menuManage',  // 菜单管理
                name: 'menuManage',
                meta: { title: '菜单管理', isShow: true },
                component: () => import('@/views/systemManage/menu/MenuManage.vue'),
                children: [
                    {
                        path: '/systemManage/menuManage/editMenu/:id',  // 编辑菜单
                        name: 'editMenu',
                        meta: { title: '新建/编辑菜单' },
                        component: () => import('@/views/systemManage/menu/EditMenu.vue'),
                    },
                ]
            },
            {
                path: '/systemManage/globalConstants',  // 全局常量
                name: 'globalConstants',
                meta: { title: '全局常量', isShow: true },
                component: () => import('@/views/systemManage/constant/GlobalConstants.vue'),
            },
            {
                path: '/systemManage/journalManage',  // 操作日志
                name: 'journalManage',
                meta: { title: '操作日志', isShow: true },
                component: () => import('@/views/systemManage/operationLog/JournalManage.vue'),
                children: [
                    {
                        path: '/systemManage/systemAbnormal/journalManageDetail/:id',  // 操作日志详情
                        name: 'journalManageDetail',
                        meta: { title: '操作日志详情' },
                        component: () => import('@/views/systemManage/operationLog/JournalManageDetail.vue'),
                    },
                ]
            },
            {
                path: '/systemManage/systemAbnormal',  // 异常日志
                name: 'systemAbnormal',
                meta: { title: '异常日志', isShow: true },
                component: () => import('@/views/systemManage/abnormalLog/SystemAbnormal.vue'),
                children: [
                    {
                        path: '/systemManage/systemAbnormal/systemAbnormalDetail/:id',  // 异常日志详情
                        name: 'systemAbnormalDetail',
                        meta: { title: '查看异常详情' },
                        component: () => import('@/views/systemManage/abnormalLog/SystemAbnormalDetail.vue'),
                    },
                ]
            },
        ]
    },
]