// 运营管理
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/operate",
    name: "operate",
    component: () => import("@/views/Home.vue"),
    meta: { title: "运营管理" },
    children: [
      {
        path: "/operate/bannerList", // 首页轮播图路由
        name: "bannerList",
        meta: { title: "首页轮播图", isShow: true },
        component: () => import("@/views/operate/BannerList.vue"),
        children: [
          {
            path: "/operate/bannerList/addBanner/:id", // 增加首页轮播图路由
            name: "addBanner",
            meta: { title: "新增/编辑轮播图", isShow: true },
            component: () => import("@/views/operate/AddBanner.vue"),
          }
        ],
      },
      {
        path: "/operate/newsCenter", // 新闻中心
        name: "newsCenter",
        meta: { title: "新闻中心", isShow: true },
        component: () => import("@/views/operate/newsCenter/NewsCenter.vue"),
        children:[
          {
            path: "/operate/newsCenter/addNews/:id", // 新增/编辑新闻
            name: "addNews",
            meta: { title: "新增/编辑新闻", isShow: true },
            component: () => import("@/views/operate/newsCenter/AddNews.vue"),
          }
        ]
      },
      {
        path: "/operate/customerservice", 
        name: "customerservice",
        meta: { title: "服务客户", isShow: true },
        component: () => import("@/views/operate/customerService/Customerservice.vue"),
        children:[
          {
            path: "/operate/customerservice/addCustomer/:id", 
            name: "addCustomer",
            meta: { title: "新增/编辑客户", isShow: true },
            component: () => import("@/views/operate/customerService/AddCustomer.vue"),
          }
        ]
      }
    ],
  },
];
